export enum CreativeTypes {
  VIDEO = 'video',
  PLAYABLE = 'playable',
  ENDCARD = 'endcard',
  BANNER = 'banner',
  IMAGE = 'image',
  ICON = 'icon'
}

export default CreativeTypes;
