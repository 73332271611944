export enum RoasPhases {
  LEARNING = 'learning',
  PRE_CAMPAIGN = 'pre-campaign',
  CAMPAIGN = 'campaign',
  CAMPAIGN_PRE_COUNTRY = 'campaign-pre-country',
  CAMPAIGN_COUNTRY = 'campaign-country',
  CAMPAIGN_PRE_SOURCE = 'campaign-pre-source',
  CAMPAIGN_SOURCE = 'campaign-source',
  CAMPAIGN_SOURCE_PRE_COUNTRY = 'campaign-source-pre-country',
  CAMPAIGN_SOURCE_COUNTRY = 'campaign-source-country',
  UNKNOWN = 'unknown'
}

export default RoasPhases;
