export enum ExAbTests {
  CONTROL = 'control',

  // Testing
  IMP_DEVICE_SIZE = 'imp-device-size',
  BID_FLOOR = 'bid-floor',
  BID_FLOOR_IMP_DEVICE_SIZE = 'bid-floor-imp-device-size'
  // ----- //
}

export default ExAbTests;
