import { useReportingTable } from '@/hooks';
import { Filter, getMetricColumn, getNumberColumn, getNumericColumn, getStringColumn } from '@/modules/reporting/helpers';
import moment from 'moment';
import qs from 'qs';
import { useMemo } from 'react';
import { Popover, Spin } from 'antd';

import { ReportLineGraph, YcColumnType, YcTable, YcTitle } from "@/components";
import { FilterTool, NumberTools, UtilTools } from "@/tools";
import { reportingBiddingService_SA } from "@/services";
import { usePageTitle } from '@/hooks/use-title';
import {YcEnums} from 'yc-interfaces/dist/src';

import {ReportingFilter} from '../../../components';

import './reporting-ssp.page.scss';


interface Total {
  requests: number;
  responses: number;
  noBids: number;
  bidRate: number;
  wins: number;
  winRate: number;
  loss: number;
  lossRate: number;
  errors: number;
  errorRate: number;
  bills: number;
  impressions: number;
  displayRate: number;
  clicks: number;
  clickRate: number;
  revenue: number;
  ecpm: number;
  loadStarts: number;
  loadEnds: number;
  loadErrors: number;
  showStarts: number;
  showEnds: number;
  showErrors: number;
  transfertCost: number;
}

interface ResultLine extends Total {
  id: number;
  date?: string;
  hourOfDay?: string;
  bidder?: string;
  publisher?: string;
  endpoint?: string;
  platform?: string;
  platformVersion?: string;
  sdkVersion?: string;
  source?: string;
  sourceVersion?: string;
  placement?: string;
  placementType?: string;
  countryCode?: string;
  lossCode?: string;
  isMediation?: boolean;
  exAbTestKey?: string;
  exAbTestName?: string;
  exAbTestVersion?: string;
  bidAbTestKey?: string;
  bidAbTestName?: string;
  bidAbTestVersion?: string;
  deviceNbApp?: number;
  deviceNbAttempt?: number;
  adType?: string;
  displaymanager?: string;
  bidErrorCode?: number;
  bidPriceSource?: number;
}

interface Result {
  is: string[],
  total: Total,
  results: ResultLine[],
}

const PARAMS_FILTERS = [
  'granularities', 'hours', 'publishers', 'bidders', 'endpoints', 'countries', 'sources', 'sourceVersions', 'platforms', 'platformVersions', 'sdkVersions',
  'placements', 'placementTypes', 'adTypes', 'exAbTestKeys', 'exAbTestNames', 'exAbTestVersions', 'bidAbTestKeys', 'bidAbTestNames', 'bidAbTestVersions',
  'lossCodes', 'displayManagers', 'bidErrorCodes', 'bidPriceSources',
];
const IS_FILTERS = [
  { filterKey: 'isGranularity', searchKey: 'granularity' },
  { filterKey: 'isHourOfDay', searchKey: 'hourOfDay' },
  { filterKey: 'isPublisher', searchKey: 'publisher' },
  { filterKey: 'isBidder', searchKey: 'bidder' },
  { filterKey: 'isEndpoint', searchKey: 'endpoint' },
  { filterKey: 'isCountry', searchKey: 'country' },
  { filterKey: 'isSource', searchKey: 'source' },
  { filterKey: 'isSourceVersion', searchKey: 'sourceVersion' },
  { filterKey: 'isPlatform', searchKey: 'platform' },
  { filterKey: 'isPlatformVersion', searchKey: 'platformVersion' },
  { filterKey: 'isSdkVersion', searchKey: 'sdkVersion' },
  { filterKey: 'isPlacement', searchKey: 'placement' },
  { filterKey: 'isPlacementType', searchKey: 'placementType' },
  { filterKey: 'isAdType', searchKey: 'adType' },
  { filterKey: 'isExAbTestKey', searchKey: 'exAbTestKey' },
  { filterKey: 'isExAbTestName', searchKey: 'exAbTestName' },
  { filterKey: 'isExAbTestVersion', searchKey: 'exAbTestVersion' },
  { filterKey: 'isBidAbTestKey', searchKey: 'bidAbTestKey' },
  { filterKey: 'isBidAbTestName', searchKey: 'bidAbTestName' },
  { filterKey: 'isBidAbTestVersion', searchKey: 'bidAbTestVersion' },
  { filterKey: 'isBidAbTestVersion', searchKey: 'bidAbTestVersion' },
  { filterKey: 'isLossCode', searchKey: 'lossCode' },
  { filterKey: 'isDeviceNbApp', searchKey: 'deviceNbApp' },
  { filterKey: 'isDeviceNbAttempt', searchKey: 'deviceNbAttempt' },
  { filterKey: 'isBidErrorCode', searchKey: 'bidErrorCode' },
  { filterKey: 'isBidPriceSource', searchKey: 'bidPriceSource' },
  { filterKey: 'isDisplayManager', searchKey: 'displayManager' },
];

function getParams(stringify: boolean, dates: [moment.Moment, moment.Moment], filters: Filters, params: any = {}) {
  const b = {
    ...(params || {}),
    dateStart: moment(dates[0]).format("YYYY-MM-DD"),
    dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
    granularity: filters?.isGranularity ? filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
    hours: FilterTool.getParamsFilter(filters?.hours),
    publishers: FilterTool.getParamsFilter(filters?.publishers),
    bidders: FilterTool.getParamsFilter(filters?.bidders),
    endpoints: FilterTool.getParamsFilter(filters?.endpoints),
    countries: FilterTool.getParamsFilter(filters?.countries),
    sources: FilterTool.getParamsFilter(filters?.sources),
    sourceVersions: FilterTool.getParamsFilter(filters?.sourceVersions),
    platforms: FilterTool.getParamsFilter(filters?.platforms),
    platformVersions: FilterTool.getParamsFilter(filters?.platformVersions),
    sdkVersions: FilterTool.getParamsFilter(filters?.sdkVersions),
    placements: FilterTool.getParamsFilter(filters?.placements),
    placementTypes: FilterTool.getParamsFilter(filters?.placementTypes),
    adTypes: FilterTool.getParamsFilter(filters?.adTypes),
    exAbTestKeys: FilterTool.getParamsFilter(filters?.exAbTestKeys),
    exAbTestNames: FilterTool.getParamsFilter(filters?.exAbTestNames),
    exAbTestVersions: FilterTool.getParamsFilter(filters?.exAbTestVersions),
    bidAbTestKeys: FilterTool.getParamsFilter(filters?.bidAbTestKeys),
    bidAbTestNames: FilterTool.getParamsFilter(filters?.bidAbTestNames),
    bidAbTestVersions: FilterTool.getParamsFilter(filters?.bidAbTestVersions),
    lossCodes: FilterTool.getParamsFilter(filters?.lossCodes),
    bidErrorCodes: FilterTool.getParamsFilter(filters?.bidErrorCodes),
    bidPriceSources: FilterTool.getParamsFilter(filters?.bidPriceSources),
    displayManagers: FilterTool.getParamsFilter(filters?.displayManagers),
    is: FilterTool.getParamsIs(IS_FILTERS, filters),
  };
  if (stringify) {
    return qs.stringify(b, { encode: false });
  }
  return b;
}

export function SAReportingSspPage() {
  usePageTitle('Reporting - Ssp');

  const {
    filters, results, tableData, dates,
    loading, onSetFilter, _setTableData, onDatesChange,
    onSetFilters, onSearchReportingFilter, onSearchDownloadData,
  } = useReportingTable<Result, ResultLine>({
    service: reportingBiddingService_SA,
    getParams,
    isFilters: IS_FILTERS,
    paramsFilters: PARAMS_FILTERS,
    initFCItemStates: FilterTool.initFCItemStates,
  });

  const columnsMemo = useMemo(() => {
    const allBCols = {
      granularity: getStringColumn<ResultLine>({ title: 'Date', dataKey: 'date', filters, onSetFilter }),
      hourOfDay: getStringColumn<ResultLine>({ title: 'Hour', dataKey: 'hourOfDay', itemKey: 'hours', filters, onSetFilter }),
      publisher: getStringColumn<ResultLine>({ title: 'Publisher', dataKey: 'publisher', itemKey: 'publishers', filters, onSetFilter }),
      bidder: getStringColumn<ResultLine>({ title: 'Bidder', dataKey: 'bidder', itemKey: 'bidders', filters, onSetFilter }),
      endpoint: getStringColumn<ResultLine>({ title: 'Endpoint', dataKey: 'endpoint', itemKey: 'endpoints', filters, onSetFilter }),
      source: getStringColumn<ResultLine>({ title: 'Source', dataKey: 'source', itemKey: 'sources', filters, onSetFilter }),
      sourceVersion: getNumericColumn<ResultLine>({ title: 'Source Version', dataKey: 'sourceVersion', itemKey: 'sourceVersions', filters, onSetFilter }),
      placement: getStringColumn<ResultLine>({ title: 'Placement', dataKey: 'placement', itemKey: 'placements', filters, onSetFilter }),
      placementType: getStringColumn<ResultLine>({ title: 'Placement Type', dataKey: 'placementType', itemKey: 'placementTypes', filters, onSetFilter }),
      country: getStringColumn<ResultLine>({ title: 'Country', dataKey: 'countryCode', itemKey: 'countries', filters, onSetFilter }),
      platform: getStringColumn<ResultLine>({ title: 'Platform', dataKey: 'platform', itemKey: 'platforms', filters, onSetFilter }),
      platformVersion: getNumericColumn<ResultLine>({ title: 'Platform Version', dataKey: 'platformVersion', itemKey: 'platformVersions', filters, onSetFilter }),
      sdkVersion: getNumericColumn<ResultLine>({ title: 'SDK Version', dataKey: 'sdkVersion', itemKey: 'sdkVersions', filters, onSetFilter }),
      lossCode: getStringColumn<ResultLine>({ title: 'Loss Code', dataKey: 'lossCode', itemKey: 'lossCodes', filters, onSetFilter }),
      bidErrorCode: getStringColumn<ResultLine>({ title: 'Bid Error Code', dataKey: 'bidErrorCode', itemKey: 'bidErrorCodes', filters, onSetFilter, renderFunction: (v) => YcEnums.BidErrors[v] || v }),
      bidPriceSource: getStringColumn<ResultLine>({ title: 'Bid Price Source', dataKey: 'bidPriceSource', itemKey: 'bidPriceSources', filters, onSetFilter, renderFunction: (v) => YcEnums.BidPriceSources[v] || v  }),
      displayManager: getStringColumn<ResultLine>({ title: 'Mediation', dataKey: 'displayManager', itemKey: 'displayManagers', filters, onSetFilter, renderFunction: (v) => YcEnums.AdNetworksDB[v] || v }),
      exAbTestKey: getStringColumn<ResultLine>({ title: 'Ex Ab Key', dataKey: 'exAbTestKey', itemKey: 'exAbTestKeys', filters, onSetFilter }),
      exAbTestName: getStringColumn<ResultLine>({ title: 'Ex Ab Name', dataKey: 'exAbTestName', itemKey: 'exAbTestNames', filters, onSetFilter }),
      exAbTestVersion: getNumberColumn<ResultLine>({ title: 'Ex Ab Version', dataKey: 'exAbTestVersion', itemKey: 'exAbTestVersions', filters, onSetFilter }),
      bidAbTestKey: getStringColumn<ResultLine>({ title: 'Bid Ab Key', dataKey: 'bidAbTestKey', itemKey: 'bidAbTestKeys', filters, onSetFilter }),
      bidAbTestName: getStringColumn<ResultLine>({ title: 'Bid Ab Name', dataKey: 'bidAbTestName', itemKey: 'bidAbTestNames', filters, onSetFilter }),
      bidAbTestVersion: getNumberColumn<ResultLine>({ title: 'Bid Ab Version', dataKey: 'bidAbTestVersion', itemKey: 'bidAbTestVersions', filters, onSetFilter }),
      adType: getStringColumn<ResultLine>({ title: 'Ad Type', dataKey: 'adType', itemKey: 'adTypes', filters, onSetFilter }),
      deviceNbApp: getNumberColumn<ResultLine>({ title: 'Nb App', dataKey: 'deviceNbApp', filterDropdown: <Filter filterKey='deviceNbApp' results={results?.results || []} _setTableData={_setTableData} />, filters, onSetFilter }),
      deviceNbAttempt: getNumberColumn<ResultLine>({ title: 'Nb Attempt', dataKey: 'deviceNbAttempt', filterDropdown: <Filter filterKey='deviceNbAttempt' results={results?.results || []} _setTableData={_setTableData} />, filters, onSetFilter }),
    }

    const columns: YcColumnType<ResultLine>[] = [
      getMetricColumn({ title: 'Requests', dataKey: 'requests', render: (_, v) => (
        <div className="cell-with-info">
          {NumberTools.largeNumber(v.requests)}
          {(results?.total.requests || 0) > 0 &&
            <span className="cell-info">({NumberTools.roundPercentage(v.requests * 100 / results!.total.requests, 2)})</span>}
        </div>), results: results?.results || [], _setTableData,
      }),
      getMetricColumn({title: 'No Bids', dataKey: 'noBids', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({title: 'Responses', dataKey: 'responses', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({ title: 'Bid Rate', dataKey: 'bidRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({title: 'Wins', dataKey: 'wins', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({ title: 'Win Rate', dataKey: 'winRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({title: 'Loss', dataKey: 'loss', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({ title: 'Loss Rate', dataKey: 'lossRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({title: 'Errors', dataKey: 'errors', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({ title: 'Error Rate', dataKey: 'errorRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Loads', dataKey: 'loadStarts', render: (_, v) => (
          <Popover content={(
            <div>
              <div style={{ "fontSize": '10px' }}>LoadStarts: {NumberTools.largeNumber(v.loadStarts)}</div>
              <div style={{ "fontSize": '10px' }}>LoadEnds: {NumberTools.largeNumber(v.loadEnds)}<span>({UtilTools.computeAndFormatPercent(v.loadStarts, v.loadEnds, 2)})</span></div>
              <div style={{ "fontSize": '10px' }}>LoadErrors: {NumberTools.largeNumber(v.loadErrors)}<span>({UtilTools.computeAndFormatPercent(v.loadStarts, v.loadErrors, 2)})</span></div>
            </div>
          )} title="Load Details">
            <div>{NumberTools.largeNumber(v.loadStarts)}</div>
          </Popover>), results: results?.results || [], _setTableData,
      }),
      getMetricColumn({ title: 'Shows', dataKey: 'showStarts', render: (_, v) => (
          <Popover content={(
            <div>
              <div style={{ "fontSize": '10px' }}>ShowStarts: {NumberTools.largeNumber(v.showStarts)}</div>
              <div style={{ "fontSize": '10px' }}>ShowEnds: {NumberTools.largeNumber(v.showEnds)}<span>({UtilTools.computeAndFormatPercent(v.showStarts, v.showEnds, 2)})</span></div>
              <div style={{ "fontSize": '10px' }}>ShowErrors: {NumberTools.largeNumber(v.showErrors)}<span>({UtilTools.computeAndFormatPercent(v.showStarts, v.showErrors, 2)})</span></div>
            </div>
          )} title="Show Details">
            <div>{NumberTools.largeNumber(v.showStarts)}</div>
          </Popover>), results: results?.results || [], _setTableData,
      }),
      getMetricColumn({title: 'Impressions', dataKey: 'impressions', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData}),
      getMetricColumn({ title: 'Display Rate', dataKey: 'displayRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({title: 'Clicks', dataKey: 'clicks', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Click Rate', dataKey: 'clickRate', numberFormat: NumberTools.roundPercentage2Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Billable Impressions', dataKey: 'bills', numberFormat: NumberTools.largeNumber, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Revenue', dataKey: 'revenue', numberFormat: NumberTools.roundPrice3Dec, config: { defaultSortOrder: 'descend' }, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'eCPM', dataKey: 'ecpm', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
      getMetricColumn({ title: 'Data Transfert', dataKey: 'transfertCost', numberFormat: NumberTools.roundPrice3Dec, results: results?.results || [], _setTableData }),
    ];
    let bCols: YcColumnType<ResultLine>[] = Object.entries(allBCols).filter(([key]) => results?.is.includes(key)).map(([_, col]) => col);
    if (bCols.length) {
      columns.unshift({ title: 'Breakdown', dataIndex: 'breakdown', key: 'breakdown', ycCanNotHide: true, children: bCols });
    }
    return columns;
  }, [_setTableData, filters, onSetFilter, results]);

  const summarys = results ? [
    { key: 'generique', value: undefined, colSpan: results!.is.length },
    { key: 'requests', value: NumberTools.largeNumber(results!.total.requests) },
    { key: 'noBids', value: NumberTools.largeNumber(results!.total.noBids) },
    { key: 'responses', value: NumberTools.largeNumber(results!.total.responses) },
    { key: 'bidRate', value: NumberTools.roundPercentage(results!.total.bidRate, 2) },
    { key: 'wins', value: NumberTools.largeNumber(results!.total.wins) },
    { key: 'winRate', value: NumberTools.roundPercentage(results!.total.winRate, 2) },
    { key: 'loss', value: NumberTools.largeNumber(results!.total.loss) },
    { key: 'lossRate', value: NumberTools.roundPercentage(results!.total.lossRate, 2) },
    { key: 'errors', value: NumberTools.largeNumber(results!.total.errors) },
    { key: 'errorRate', value: NumberTools.roundPercentage(results!.total.errorRate, 2) },
    {
      key: 'loadStarts',
      value: <Popover content={(
        <div>
          <div style={{ "fontSize": '10px' }}>LoadStarts: {NumberTools.largeNumber(results!.total.loadStarts)}</div>
          <div style={{ "fontSize": '10px' }}>LoadEnds: {NumberTools.largeNumber(results!.total.loadEnds)}<span>({UtilTools.computeAndFormatPercent(results!.total.loadStarts, results!.total.loadEnds, 2)})</span></div>
          <div style={{ "fontSize": '10px' }}>LoadErrors: {NumberTools.largeNumber(results!.total.loadErrors)}<span>({UtilTools.computeAndFormatPercent(results!.total.loadStarts, results!.total.loadErrors, 2)})</span></div>
        </div>
      )} title="Load Details">
        <div>{NumberTools.largeNumber(results!.total.loadStarts)}</div>
      </Popover>
    },
    {
      key: 'showStarts',
      value: <Popover content={(
        <div>
          <div style={{ "fontSize": '10px' }}>ShowStarts: {NumberTools.largeNumber(results!.total.showStarts)}</div>
          <div style={{ "fontSize": '10px' }}>ShowEnds: {NumberTools.largeNumber(results!.total.showEnds)}<span>({UtilTools.computeAndFormatPercent(results!.total.showStarts, results!.total.showEnds, 2)})</span></div>
          <div style={{ "fontSize": '10px' }}>ShowErrors: {NumberTools.largeNumber(results!.total.showErrors)}<span>({UtilTools.computeAndFormatPercent(results!.total.showStarts, results!.total.showErrors, 2)})</span></div>
        </div>
      )} title="Show Details">
        <div>{NumberTools.largeNumber(results!.total.showStarts)}</div>
      </Popover>
    },
    { key: 'impressions', value: NumberTools.largeNumber(results!.total.impressions) },
    { key: 'displayRate', value: NumberTools.roundPercentage(results!.total.displayRate, 2) },
    { key: 'clicks', value: NumberTools.largeNumber(results!.total.clicks) },
    { key: 'clickRate', value: NumberTools.roundPercentage(results!.total.clickRate, 2) },
    { key: 'bills', value: NumberTools.largeNumber(results!.total.bills) },
    { key: 'revenue', value: NumberTools.roundPrice(results!.total.revenue, 3) },
    { key: 'ecpm', value: NumberTools.roundPrice(results!.total.ecpm, 3) },
    { key: 'transfertCost', value: NumberTools.roundPrice(results!.total.transfertCost, 3) },
  ] : undefined;
  const filterProps: FilterPropType[] = useMemo(
    () => [
      // TIME
      { key: 'granularities', itemKey: 'granularities', isKey: 'isGranularity', label: 'Granularities', show: true },
      { key: 'hours', itemKey: 'hours', isKey: 'isHourOfDay', label: 'Hours', mode: 'multiple' as const, show: true },
      // Bidder
      // { key: 'bidders', itemKey: 'bidders', isKey: 'isBidder', label: 'Bidders', mode: 'multiple' as const, show: true },
      // { key: 'endpoints', itemKey: 'endpoints', isKey: 'isEndpoint', label: 'Endpoints', mode: 'multiple' as const, show: true },
      // Publisher
      { key: 'displayManagers', itemKey: 'displayManagers', isKey: 'isDisplayManager', label: 'Mediation', mode: 'multiple' as const, show: true },
      { key: 'publishers', itemKey: 'publishers', isKey: 'isPublisher', label: 'Publishers', mode: 'multiple' as const, show: true },
      { key: 'sources', itemKey: 'sources', isKey: 'isSource', label: 'Sources', mode: 'multiple' as const, show: true },
      { key: 'sourceVersions', itemKey: 'sourceVersions', isKey: 'isSourceVersion', label: 'Source Versions', mode: 'multiple' as const, show: true },
      { key: 'placements', itemKey: 'placements', isKey: 'isPlacement', label: 'Placements', mode: 'multiple' as const, show: true },
      // Tests
      // { key: 'exAbTestKeys', itemKey: 'exAbTestKeys', isKey: 'isExAbTestKey', label: 'Ex Ab Keys', mode: 'multiple' as const, show: true },
      // { key: 'exAbTestNames', itemKey: 'exAbTestNames', isKey: 'isExAbTestName', label: 'Ex Ab Names', mode: 'multiple' as const, show: true },
      // { key: 'exAbTestVersions', itemKey: 'exAbTestVersions', isKey: 'isExAbTestVersion', label: 'Ex Ab Versions', mode: 'multiple' as const, show: true },
      { key: 'bidAbTestKeys', itemKey: 'bidAbTestKeys', isKey: 'isBidAbTestKey', label: 'Bid Ab Keys', mode: 'multiple' as const, show: true },
      { key: 'bidAbTestName', itemKey: 'bidAbTestNames', isKey: 'isBidAbTestName', label: 'Bid Ab Names', mode: 'multiple' as const, show: true },
      { key: 'bidAbTestVersions', itemKey: 'bidAbTestVersions', isKey: 'isBidAbTestVersion', label: 'Bid Ab Versions', mode: 'multiple' as const, show: true },
      // Geography
      { key: 'countries', itemKey: 'countries', isKey: 'isCountry', label: 'Countries', mode: 'multiple' as const, show: true },
      // Platform
      { key: 'platforms', itemKey: 'platforms', isKey: 'isPlatform', label: 'Platforms', mode: 'multiple' as const, show: true },
      { key: 'platformVersion', itemKey: 'platformVersions', isKey: 'isPlatformVersion', label: 'Platform Versions', mode: 'multiple' as const, show: true },
      { key: 'sdkVersions', itemKey: 'sdkVersions', isKey: 'isSdkVersion', label: 'SDK Versions', mode: 'multiple' as const, show: true },
      // Ad
      { key: 'adTypes', itemKey: 'adTypes', isKey: 'isAdType', label: 'Ad Types', mode: 'multiple' as const, show: true },
      { key: 'placementTypes', itemKey: 'placementTypes', isKey: 'isPlacementType', label: 'Placement Types', mode: 'multiple' as const, show: true },
      // Device
      { key: 'deviceNbApps', itemKey: 'deviceNbApps', isKey: 'isDeviceNbApp', label: 'Nb Apps', disabledSelect: true, show: true },
      { key: 'deviceNbAttempts', itemKey: 'deviceNbAttempts', isKey: 'isDeviceNbAttempt', label: 'Nb Attempts', disabledSelect: true, show: true },
      // Error codes
      { key: 'bidPriceSources', itemKey: 'bidPriceSources', isKey: 'isBidPriceSource', label: 'Bid Price Sources', mode: 'multiple' as const, show: true },
      { key: 'bidErrorCodes', itemKey: 'bidErrorCodes', isKey: 'isBidErrorCode', label: 'Bid Error Codes', mode: 'multiple' as const, show: true },
      { key: 'lossCodes', itemKey: 'lossCodes', isKey: 'isLossCode', label: 'Lose Codes', mode: 'multiple' as const, show: true },
    ].map((f) => ({ ...f, filters: filters, onSetFilters: onSetFilters })),
    [filters, onSetFilters],
  );

  return (
    <div id='reporting-ssp'>
      <YcTitle label="Reporting - Ssp" ></YcTitle>
      <Spin spinning={loading}>
        <div className="mb-3">
          <ReportingFilter
            datePresets={['Today', 'Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date'] as const}
            dates={dates}
            filterProps={filterProps}
            loading={loading}
            onDatesChange={onDatesChange}
            onSearch={onSearchReportingFilter}
            onSearchDownloadData={onSearchDownloadData}
          />
        </div>
        {!!results && (
          <div>
            <div className="mb-4">
              <ReportLineGraph data={results?.results} is={results?.is} fields={['revenue', 'transfertCost']} repeatFields />
            </div>
            <YcTable
              bordered
              size="small"
              dataSource={tableData}
              columns={columnsMemo}
              ycSummarys={summarys}
              ycTableKey="reporting-bidding"
              rowKey="id"
              rowClassName={(record) => (record.bidAbTestName === 'control' ? 'control-row' : '')}
            />
          </div>
        )}
      </Spin>
    </div>
  );
}
