/**
 * The following table lists the types of image mimes.
 */
export enum ImageMimes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif'
}

/**
 * The following table lists the types of video mimes.
 */
export enum VideoMimes {
  MP4 = 'video/mp4', // .mp4, .m4v, .m4p
  MPEG = 'video/mpeg', // .mpg, .mpeg
  M3U = 'application/x-mpegURL', // .m3u .m3u8
  OGG = 'video/ogg', // .ogv, .ogg
  WEBM = 'video/webm', // .webm
  X_M4V = 'video/x-m4v', // apple .m4v
  MOV = 'video/quicktime', // apple .mov
  AVI = 'video/x-msvideo', // microsoft .avi
  FLV = 'video/x-flv', // flash .flv
  '3GPP' = 'video/3gpp' // .3gp
}

type Mimes = ImageMimes | VideoMimes | string;

export default Mimes;
