export enum AerospikeSets {
  AD = 'Ad',
  AD_COMPUTE = 'AdCompute',
  AD_EVENT = 'AdEvent',
  ADMIN_CONFIG = 'AdminConfig',
  APPLICATION = 'Application',
  BIDDER_CONFIG = 'BidderConfig',
  CAMPAIGN = 'Campaign',
  DEVICE_APP_COMPUTE = 'DeviceAppCompute',
  DEVICE_AD_COMPUTE = 'DeviceAdCompute',
  DEVICE_COMPUTE = 'DeviceCompute',
  DEVICE_SESSION = 'DeviceSession',
  KEY_VALUE = 'KeyValue',
  PLACEMENT = 'Placement',
  PLACEMENT_EVENT = 'PlacementEvent',
  CAMPAIGN_COMPUTE = 'CampaignCompute',
  SOURCE_COMPUTE = 'SourceCompute',
  TRACK_EVENT = 'TrackEvent'
}

export default AerospikeSets;
