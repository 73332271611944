import { useUrlParams } from '@/hooks/use-url-params';
import moment from "moment";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import { MCampaign } from '@/modeles';
import { campaignsService, reportingAdvertisingService } from '@/services';

const TOTAL_INITIAL_STATE = {
  impressions: 0,
  clicks: 0,
  installs: 0,
  ecpi: 0,
  ecpm: 0,
  ipm: 0,
  ir: 0,
  spend: 0,
};

function getParams(dates: [moment.Moment, moment.Moment]) {
  return {
    dateStart: moment(dates[0]).format("YYYY-MM-DD"),
    dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
  }
}

async function fetchCampaigns(dates: [moment.Moment, moment.Moment], isSuperAdmin?: boolean) {
  return reportingAdvertisingService.search({
    ...getParams(dates),
    is: "campaign",
    isSuperAdmin,
  });
}

function mapResultsToCampaigns(campaigns: MCampaign[], results) {
  const cpgByKey = results.reduce((acc, next) => {
    acc[next.campaignKey] = next;

    return acc;
  }, {});
  return campaigns.map((camp) => {
    const k = camp.key;
    const result = cpgByKey[k];

    return {
      ...camp,
      impressions: result?.impressions || 0,
      clicks: result?.clicks || 0,
      installs: result?.installs || 0,
      ir: result?.ir || 0,
      ecpi: result?.ecpi || 0,
      realEcpi: result?.realEcpi || 0,
      ecpm: result?.ecpm || 0,
      realEcpm: result?.realEcpm || 0,
      spend: result?.spend || 0,
      realSpend: result?.realSpend || 0,
    };
  });

}

function useCampaignFetch(isSuperAdmin?: boolean) {
  const [campaigns, _setCampaigns] = useState<MCampaign[]>([]);
  const [campaignsToValidate, _setCampaignsToValidate] = useState<MCampaign[]>([]);
  const [loading, _setLoading] = useState<boolean>(false);
  const [dates, _setDates] = useState<[moment.Moment, moment.Moment]>([
    moment().subtract(1, 'day'),
    moment().subtract(1, 'day'),
  ]);
  const [hasReporting, _setHasReporting] = useState<boolean>(false);
  const [total, _setTotal] = useState(TOTAL_INITIAL_STATE);
  const { getUrlParam } = useUrlParams();
  const uHistory = useHistory();

  useEffect(() => {
    const dateStart = getUrlParam('dateStart');
    const dateEnd = getUrlParam('dateEnd');
    _setDates([dateStart ? moment(dateStart) : dates[0], dateEnd ? moment(dateEnd) : dates[1]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUrlParam]);

  useEffect(() => {
    _setLoading(true);
    if (isSuperAdmin) {
      campaignsService.listingSuperAdmin().then(async (data) => {
        _setCampaigns(data || []);
        const campaignFiltered = (data || []).filter((campaign) => campaign.isValide === null);
        await Promise.all(
          campaignFiltered.map(async (element) => {
            element.previewStatus = await campaignsService.previewStatus(element.key);
          })
        );
        _setCampaignsToValidate(campaignFiltered);
        _setLoading(false);
      });
    } else {
      campaignsService.listing().then(async (data) => {
        _setCampaigns(data || []);
        _setLoading(false);
      });
    }
  }, [isSuperAdmin]);

  const onLoadStats = async () => {
    _setLoading(true);
    uHistory.push({ search: new URLSearchParams(getParams(dates)).toString() });
    const data = await fetchCampaigns(dates, isSuperAdmin);
    if (data) {
      if (data && data.total) {
        _setTotal(data.total);
      }
      if (data.results && data.results.length) {
        _setCampaigns(mapResultsToCampaigns(campaigns, data.results));
      }
      _setHasReporting(true);
    }
    _setLoading(false);
  }

  return {
    _setCampaigns,
    _setDates,
    campaigns,
    campaignsToValidate,
    dates,
    hasReporting,
    loading,
    onLoadStats,
    total,
  };
}

function useCampaignsFilters(campaigns: MCampaign[]) {
  const [displayCampaigns, setDisplayCampaigns] = useState<MCampaign[]>([]);
  const [filters, _setFilters] = useState({
    search: '',
  });

  const onSearch: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    _setFilters({ ...filters, search: value });
  }

  useEffect(() => {
    const displayCampaigns = campaigns.filter((c) => {
      let checkSearch = true;
      if (filters.search) {
        const checkName = c.name.toLowerCase().includes(filters.search?.toLowerCase());
        const checkBundle = c.applicationKey?.toLowerCase().includes(filters.search?.toLowerCase());
        const checkAppName = c.Application?.name?.toLowerCase().includes(filters.search?.toLowerCase());
        const checkAppKey = c.Application?.key?.toLowerCase().includes(filters.search?.toLowerCase());
        const checkKey = c.key.toLowerCase().includes(filters.search?.toLowerCase());

        checkSearch = checkName || checkBundle || checkKey || checkAppName || checkAppKey;
      }

      return checkSearch;
    });

    setDisplayCampaigns(displayCampaigns);
  }, [campaigns, filters]);

  return { displayCampaigns, onSearch };
}

export function useCampaigns(isSuperAdmin?: boolean) {
  const campaignsInfo = useCampaignFetch(isSuperAdmin);
  const { displayCampaigns, onSearch } = useCampaignsFilters(campaignsInfo.campaigns);

  return {
    ...campaignsInfo,
    displayCampaigns,
    onSearch,
  };
}
