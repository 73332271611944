export enum AdNetworks {
  YSOCORP = 'ysocorp',
  YSONETWORK = 'ysonetwork',
  APPLOVIN = 'applovin',
  OUTFIT_7 = 'outfit7',
  CAS_AI = 'cas_ai',
  TRADPLUS = 'TradPlus',
  GOOGLE = 'google',
  KWALEE = 'kwalee',
  UNKNOWN = 'unknown'
}

export enum AdNetworksDB {
  APPLOVIN = 'applovin_mediation',
  OUTFIT_7 = 'outfit7_mediation',
  CAS_AI = 'cas_ai_mediation',
  KWALEE = 'kwalee_mediation',
  TRADPLUS = 'TradPlus'
}

export default AdNetworks;
