import { Link } from 'react-router-dom';

import './footer.scss';

export function YcFooter() {
  return (
    <footer className="footer row container-fluid m-auto mt-5">
      <div className="credits">Copyright © 2025 YSO Network. All rights reserved.</div>
      <div className='d-flex justify-content-center gap-2'>
        <Link to="/privacy">Privacy</Link>
        |
        <Link to="/terms">Terms</Link>
        |
        <Link to="/policies-publishers">Policies Publishers</Link>
        |
        <Link to="/policies-advertisers">Policies Advertisers</Link>
        |
        <Link to="/legal-mentions">Legal Mentions</Link>
        |
        <Link to="/data-processing-agreement">Data processing agreement</Link>
      </div>
    </footer>
  );
}
