import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { CampaignUpdateMetadataType, CampaignCountry } from './types';
import { formatMoney, formatPercent } from './utils';
import { Button } from 'antd';

function countryByKey<T, N extends { key: string; }>(acc: T, next: N) {
  acc[next.key] = next;

  return acc;
}

export function CampaignUpdateMetadata({ metadata }: CampaignUpdateMetadataType) {
  const [expand, _setExpand] = useState(false);

  const { new: cur, old } = metadata;

  const { changedCountries, avgChange, nbChanges } = useMemo(() => {
    const oldByKey = old?.countries?.reduce(countryByKey, {} as { [k: string]: CampaignCountry; }) || {};

    const changes = cur?.countries?.map((country) => ({
      ...country,
      oldStatus: oldByKey[country.key]?.isActive,
      oldCpi: oldByKey[country.key]?.cpi,
      change: (oldByKey[country.key]?.cpi || oldByKey[country.key]?.roas) ? (((country.roas || country.cpi || 0) / (oldByKey[country.key]?.roas || oldByKey[country.key]?.cpi || 0)) - 1) : 1,
      oldRoas: oldByKey[country.key]?.roas,
      changeRoas: oldByKey[country.key]?.roas ? (((country.roas || 0) / (0)) - 1) : 1,
    }))?.filter((config) => config.change !== 0).sort((a, b) => {
      if ((a.oldCpi || 0) === 0 && (a.oldRoas || 0) === 0) {
        return -1;
      }

      return ((Math.abs(b.change || b.changeRoas) - 1) - (Math.abs(a.change || a.changeRoas) - 1))
    }) || [];

    const { sum, count } = changes.reduce((acc, next) => {
      acc.sum += next.change;
      acc.count++;

      return acc;
    }, { sum: 0, count: 0 });

    if (expand) {
      return { changedCountries: changes, avgChange: sum / count, nbChanges: changes.length };
    }
    return { changedCountries: changes.slice(0, 10), avgChange: sum / count, nbChanges: changes.length };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, expand]);

  if (!old) {
    return 'No old data';
  }
  if (!cur) {
    return 'No new data';
  }

  return (
    <div>
      <div><Link to={`/advertising/campaigns/campaign/${metadata.campaignKey}`}>{metadata.campaignKey}</Link></div>
      {cur.budget !== old.budget && <div>New budget : {cur.budget} (old = {old.budget})</div>}
      {cur.isActive !== old.isActive && <div>New status : {cur.isActive ? 'Active' : 'Inactive'}</div>}
      {cur.name !== old.name && <div>New name : {cur.name}</div>}
      {cur.Videos?.length !== old.Videos?.length && <div>{cur.Videos?.length - old.Videos?.length} new videos</div>}
      {cur.Images?.length !== old.Images?.length && <div>{cur.Images?.length - old.Images?.length} new images</div>}
      {cur.Endcards?.length !== old.Endcards?.length && <div>{cur.Endcards?.length - old.Endcards?.length} new endcards</div>}
      {cur.Playables?.length !== old.Playables?.length && <div>{cur.Playables?.length - old.Playables?.length} new playables</div>}
      {cur.Banners?.length !== old.Banners?.length && <div>{cur.Banners?.length - old.Banners?.length} new banners</div>}
      {changedCountries.length && (
        <div>
          <div>Avg change : {formatPercent(avgChange)}</div>
          <table>
            <table>
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Old</th>
                  <th>New</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                {changedCountries.map((country) => (
                  <tr key={country.key}>
                    <td>{country.key}</td>
                    <td>{formatMoney(country.oldCpi, 3)}</td>
                    <td>{formatMoney(country.cpi, 3)}</td>
                    <td>{formatPercent(country.change)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </table>
          {nbChanges > 10 && <Button onClick={() => _setExpand(!expand)}>{ expand ? 'less' : 'more' }</Button>}
        </div>
      )}
    </div>
  );
}
