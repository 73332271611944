import { Button, InputNumber, Space } from "antd";
import React from "react";
import "./having-filter.component.scss";

const MODES = ['<', '<=', '=', '>=', '>'];

const FILTER_FUNCTIONS = {
  [MODES[0]]: (value: number, target: number) => value < target,
  [MODES[1]]: (value: number, target: number) => value <= target,
  [MODES[2]]: (value: number, target: number) => value === target,
  [MODES[3]]: (value: number, target: number) => value >= target,
  [MODES[4]]: (value: number, target: number) => value > target,
}

function FilterMode(props: { mode: string; setMode: (mode: string) => void }) {
  return (
    <Button
      size="small"
      type="text"
      onClick={() => props.setMode(MODES[(MODES.indexOf(props.mode) + 1) % MODES.length])}
    >
      {props.mode}
    </Button>
  );
}

export function HavingFilter(props: {
  onFilter: (value: number, compare: (a: number, b: number) => boolean) => void;
  onReset: () => void;
  filterKey: string;
}) {
  const [min, _setMin] = React.useState(0);
  const [mode, _setMode] = React.useState('>=');

  return (<div className="p-1">
    <div className="mb-2 w-100">
      <InputNumber
        addonBefore={<FilterMode mode={mode} setMode={_setMode} />}
        type="number"
        min={0}
        placeholder={`Minimum ${props.filterKey}`}
        defaultValue={min}
        onChange={v => {
        if (v) {
          _setMin(v);
        }
      }} />
    </div>
    <Space>
      <Button size="small" onClick={() => {
        _setMin(0);
        props.onReset();
      }}>Reset</Button>
      <Button size="small" type="primary" onClick={() => props.onFilter(min, FILTER_FUNCTIONS[mode])}>Search</Button>
    </Space>
  </div>);
}
