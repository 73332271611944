import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { usersService } from '@/services/users.service';
import { MUser } from '@/modeles';

import './user.page.scss';
import { useAuths } from '@/contexts/auths.context';
import { SelfUser, OtherUser } from './components';
import { usersService_SA } from '@/services';
import { setPageTitle } from '@/hooks/use-title';

export function UserPage() {
  const { user: authUser } = useAuths();

  const [isReady, _setIsReady] = useState<boolean>(false);
  const [user, _setUser] = useState<MUser>(new MUser());

  const uHistory = useHistory();
  const uParams: any = useParams();
  const loc = useLocation();
  const accountId = useMemo(() => new URLSearchParams(loc.search).get('accountId'), [loc.search]);

  useEffect(() => {
    _setUser({ ...user, accountId: Number(accountId) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  useEffect(() => {
    if (!!uParams['id']) {
      usersService.view(uParams['id']).then((data) => {
        if (data) {
          _setUser(data);
          setPageTitle(`User - ${data.email}`);
          _setIsReady(true);
        } else {
          uHistory.push("/users");
        }
      });
    } else {
      _setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const onFinish = async (values: any) => {
    const c = { ...user, ...values };
    if (!!user?.id) {
      const data = await usersService.edit(user?.id, c);
      if (data) {
        _setUser(data);
      }
    }
  };


  const onFinishAdmin = async (values: any) => {
    const c = { ...user, ...values };
    if (!!user?.id) {
      const data = await usersService_SA.edit(user?.id, c);
      if (data) {
        _setUser(data);
      }
    } else {
      const data = await usersService.add(c, accountId)
      if (data) {
        _setUser(data);
        uHistory.push("/users/user/" + data?.id);
      }
    }
  };


  if (!authUser || !isReady) {
    return (<></>);
  }

  if (authUser.id === user?.id) {
    return (
      <SelfUser user={user} onFinish={onFinish} />
    )
  }

  return <OtherUser user={user} onFinish={onFinishAdmin} />
}
