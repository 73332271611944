import qs from "qs";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Form, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { YcFieldCheck, YcDatePicker, } from '@/components';
import {
  ReportingBiddingService_SA,
  ReportingAdvertisingService_SA,
  ReportingBillingService_SA,
} from "@/services";

import "./reporting-filter.component.scss";

export interface FiltersSuperAdmin {
  abTests: FCItem[];
  abTestVersions: FCItem[];
  adPhases: FCItem[];
  ads: FCItem[];
  adTypes: FCItem[];
  advertisers: FCItem[];
  applications: FCItem[];
  accounts: FCItem[];
  bidAbTests: FCItem[];
  bidAbTestKeys: FCItem[];
  bidAbTestNames: FCItem[];
  bidAbTestVersions: FCItem[];
  bidders: FCItem[];
  campaigns: FCItem[];
  countries: FCItem[];
  creativeTypes: FCItem[];
  deviceApps: FCItem[];
  deviceAttempts: FCItem[];
  endpoints: FCItem[];
  exAbTests: FCItem[];
  exAbTestKeys: FCItem[];
  exAbTestNames: FCItem[];
  exAbTestVersions: FCItem[];
  granularities: FCItem[];
  hours: FCItem[];
  lossCodes: FCItem[];
  placements: FCItem[];
  placementPhases: FCItem[];
  campaignTypes: FCItem[];
  roasPhases: FCItem[];
  bidPriceSources: FCItem[];
  placementTypes: FCItem[];
  platforms: FCItem[];
  platformVersions: FCItem[];
  publishers: FCItem[];
  rangeBidIrs: FCItem[];
  sdkVersions: FCItem[];
  sources: FCItem[];
  sourceVersions: FCItem[];
  hasIfa: FCItem[];

  isGranularity: boolean;
  isHourOfDay: boolean;
  isAccount: boolean;
  isAdvertiser: boolean;
  isPublisher: boolean;
  isBidder: boolean;
  isEndpoint: boolean;
  isPlatform: boolean;
  isPlatformVersion: boolean;
  isSdkVersion: boolean;
  isApplication: boolean;
  isCampaign: boolean;
  isAd: boolean;
  isPlacement: boolean;
  isPlacementType: boolean;
  isPlacementPhase: boolean;
  isCampaignType: boolean;
  isRoasPhase: boolean;
  isBidPriceSource: boolean;
  isLossCode: boolean;
  isSource: boolean;
  isAdType: boolean;
  isAdPhase: boolean;
  isCreativeType: boolean;
  isBidAbTest: boolean;
  isExAbTest: boolean;
  isAbTest: boolean;
  isAbTestVersion: boolean;
  isCountry: boolean;
  isDeviceNbApp: boolean;
  isDeviceNbAttempt: boolean;
  isRangeBidIr: boolean;
  isExAbTestKey: boolean;
  isExAbTestName: boolean;
  isExAbTestVersion: boolean;
  isBidAbTestKey: boolean;
  isBidAbTestName: boolean;
  isBidAbTestVersion: boolean;
  isSourceVersion: boolean;
  isHasIfa: boolean;
}

function Filter(props: { filters: any; is: string; label: string; items?: string; onSetFilters: (setter: (current: any) => any) => void; multiple?: boolean; }) {
  return (
    <Form.Item>
      <YcFieldCheck is={props.filters![props.is]} label={props.label} items={props.items ? props.filters![props.items] : []}
                    onChange={(filters, is): void => {
          props.onSetFilters((current) => ({
            ...current,
            ...(props.items ? { [props.items]: filters } : {}),
            [props.is]: is
          }));
        }} mode={props.multiple ? 'multiple' : undefined} disabledSelect={!props.items} />
    </Form.Item>
  );
}

export function ReportingFilterSuperAdmin(props: {
  onSearch: (data: any) => void;
  onSetFilters: (filters: any) => void;
  filters: any;
  type: ('advertising' | 'billing'),
  service: ReportingAdvertisingService_SA | ReportingBiddingService_SA | ReportingBillingService_SA,
}) {

  const [dates, _setDates] = useState<[moment.Moment, moment.Moment]>([moment(), moment()]);
  const [loading, _setLoading] = useState<boolean>(false);

  const uLocation = useLocation();
  const uHistory = useHistory();

  const SetParamsFilter = (items: FCItem[], paramsString: string) => {
    if (paramsString) {
      const params = paramsString.split(',');
      items.forEach((item) => {
        if (params.includes(item.value + "-Y")) {
          item.checked = true;
        } else if (params.includes(item.value + "-N")) {
          item.checked = false;
        }
      });
    }
  }

  useEffect(() => {
    _setLoading(true);
    const search = new URLSearchParams(uLocation.search);
    const params: { granularity: string | null } = { granularity: null };
    if (search.has('dateStart')) {
      dates[0] = moment(search.get('dateStart'));
    }
    if (search.has('dateEnd')) {
      dates[1] = moment(search.get('dateEnd'));
    }
    if (search.has('is') && search.has('granularity')) {
      const is = search.get('is')!.split(',');
      if (is.includes('granularity')) {
        params.granularity = search.get('granularity');
      }
    }
    props.service.filters(getParams(false, params)).then((data: FiltersSuperAdmin) => {
      _setLoading(false);
      if (data) {
        SetParamsFilter(data.granularities, search.get('granularities')!);
        SetParamsFilter(data.hours, search.get('hours')!);
        SetParamsFilter(data.countries, search.get('countries')!);
        SetParamsFilter(data.platforms, search.get('platforms')!);
        SetParamsFilter(data.sources, search.get('sources')!);
        SetParamsFilter(data.placementTypes, search.get('placementTypes')!);
        if (props.type === 'advertising') {
          SetParamsFilter(data.sourceVersions, search.get('sourceVersions')!);
          SetParamsFilter(data.sdkVersions, search.get('sdkVersions')!);
          SetParamsFilter(data.placementPhases, search.get('placementPhases')!);
          SetParamsFilter(data.campaignTypes, search.get('campaignTypes')!);
          SetParamsFilter(data.roasPhases, search.get('roasPhases')!);
          SetParamsFilter(data.bidPriceSources, search.get('bidPriceSources')!);
          SetParamsFilter(data.advertisers, search.get('advertisers')!);
          SetParamsFilter(data.publishers, search.get('publishers')!);
          SetParamsFilter(data.adPhases, search.get('adPhases')!);
          SetParamsFilter(data.bidAbTests, search.get('bidAbTests')!);
          SetParamsFilter(data.abTests, search.get('abTests')!);
          SetParamsFilter(data.abTestVersions, search.get('abTestVersions')!);
          SetParamsFilter(data.deviceApps, search.get('deviceApps')!);
          SetParamsFilter(data.deviceAttempts, search.get('deviceAttempts')!);
          SetParamsFilter(data.rangeBidIrs, search.get('rangeBidIrs')!);
          SetParamsFilter(data.applications, search.get('applications')!);
          SetParamsFilter(data.campaigns, search.get('campaigns')!);
          SetParamsFilter(data.adTypes, search.get('adTypes')!);
          SetParamsFilter(data.creativeTypes, search.get('creativeTypes')!);
          SetParamsFilter(data.hasIfa, search.get('hasIfa')!);
        }
        if (props.type === 'billing') {
          SetParamsFilter(data.accounts, search.get('accounts')!);
        }
        if (search.has('is')) {
          const is = search.get('is')!.split(',');
          data.isGranularity = is.includes('granularity');
          data.isHourOfDay = is.includes('hourOfDay');
          data.isAccount = is.includes('account');
          data.isAdvertiser = is.includes('advertiser');
          data.isPublisher = is.includes('publisher');
          data.isBidder = is.includes('bidder');
          data.isApplication = is.includes('application');
          data.isCampaign = is.includes('campaign');
          data.isAd = is.includes('ad');
          data.isCountry = is.includes('country');
          data.isEndpoint = is.includes('endpoint');
          data.isPlatform = is.includes('platform');
          data.isPlatformVersion = is.includes('platformVersion');
          data.isSourceVersion = is.includes('sourceVersion');
          data.isSdkVersion = is.includes('sdkVersion');
          data.isPlacementType = is.includes('placementType');
          data.isPlacementPhase = is.includes('placementPhase');
          data.isCampaignType = is.includes('campaignType');
          data.isRoasPhase = is.includes('roasPhase');
          data.isBidPriceSource = is.includes('bidPriceSource');
          data.isPlacement = is.includes('placement');
          data.isLossCode = is.includes('lossCode');
          data.isSource = is.includes('source');
          data.isAdType = is.includes('adType');
          data.isAdPhase = is.includes('adPhase');
          data.isCreativeType = is.includes('creativeType');
          data.isBidAbTest = is.includes('bidAbTest');
          data.isExAbTest = is.includes('exAbTest');
          data.isAbTest = is.includes('abTest');
          data.isAbTestVersion = is.includes('abTestVersion');
          data.isDeviceNbApp = is.includes('deviceNbApp');
          data.isDeviceNbAttempt = is.includes('deviceNbAttempt');
          data.isRangeBidIr = is.includes('rangeBidIr');
          data.isExAbTestKey = is.includes('exAbTestKey');
          data.isExAbTestName = is.includes('exAbTestName');
          data.isExAbTestVersion = is.includes('exAbTestVersion');
          data.isBidAbTestKey = is.includes('bidAbTestKey');
          data.isBidAbTestName = is.includes('bidAbTestName');
          data.isBidAbTestVersion = is.includes('bidAbTestVersion');
          data.isHasIfa = is.includes('hasIfa');
        }
        props.onSetFilters(data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getParamsFilter = (items: FCItem[] = []) => {
    const results = items.filter((item) => item.checked !== undefined);
    return results.map((item) => item.value + "-" + (item.checked ? "Y" : "N")).join() || undefined;
  }
  const getParamsIs = () => {
    const is: string[] = [];
    if (props.filters?.isGranularity) { is.push("granularity"); }
    if (props.filters?.isHourOfDay) { is.push("hourOfDay"); }
    if (props.filters?.isAccount) { is.push("account"); }
    if (props.filters?.isAdvertiser) { is.push("advertiser"); }
    if (props.filters?.isPublisher) { is.push("publisher"); }
    if (props.filters?.isBidder) { is.push("bidder"); }
    if (props.filters?.isApplication) { is.push("application"); }
    if (props.filters?.isCampaign) { is.push("campaign"); }
    if (props.filters?.isSource) { is.push("source"); }
    if (props.filters?.isAdType) { is.push("adType"); }
    if (props.filters?.isAdPhase) { is.push("adPhase"); }
    if (props.filters?.isCreativeType) { is.push("creativeType"); }
    if (props.filters?.isBidAbTest) { is.push("bidAbTest"); }
    if (props.filters?.isExAbTest) { is.push("exAbTest"); }
    if (props.filters?.isAbTest) { is.push("abTest"); }
    if (props.filters?.isAbTestVersion) { is.push("abTestVersion"); }
    if (props.filters?.isAd) { is.push("ad"); }
    if (props.filters?.isPlacementType) { is.push("placementType"); }
    if (props.filters?.isPlacementPhase) { is.push("placementPhase"); }
    if (props.filters?.isCampaignType) { is.push("campaignType"); }
    if (props.filters?.isRoasPhase) { is.push("roasPhase"); }
    if (props.filters?.isBidPriceSource) { is.push("bidPriceSource"); }
    if (props.filters?.isPlacement) { is.push("placement"); }
    if (props.filters?.isLossCode) { is.push("lossCode"); }
    if (props.filters?.isCountry) { is.push("country"); }
    if (props.filters?.isNetwork) { is.push("network"); }
    if (props.filters?.isEndpoint) { is.push("endpoint"); }
    if (props.filters?.isPlatform) { is.push("platform"); }
    if (props.filters?.isPlatformVersion) { is.push("platformVersion"); }
    if (props.filters?.isSourceVersion) { is.push('sourceVersion'); }
    if (props.filters?.isSdkVersion) { is.push("sdkVersion"); }
    if (props.filters?.isDeviceMake) { is.push("deviceMake"); }
    if (props.filters?.isDeviceNbApp) { is.push("deviceNbApp"); }
    if (props.filters?.isDeviceNbAttempt) { is.push("deviceNbAttempt"); }
    if (props.filters?.isRangeBidIr) { is.push("rangeBidIr"); }
    if (props.filters?.isExAbTestKey) { is.push('exAbTestKey'); }
    if (props.filters?.isExAbTestName) { is.push('exAbTestName'); }
    if (props.filters?.isExAbTestVersion) { is.push('exAbTestVersion'); }
    if (props.filters?.isBidAbTestKey) { is.push('bidAbTestKey'); }
    if (props.filters?.isBidAbTestName) { is.push('bidAbTestName'); }
    if (props.filters?.isBidAbTestVersion) { is.push('bidAbTestVersion'); }
    if (props.filters?.isHasIfa) { is.push('hasIfa'); }
    if (is.length) {
      return is.join(',');
    }
    return undefined;
  }

  const getParams = (stringify: boolean, params: any = {}) => {

    const b = {
      ...(params || {}),
      dateStart: moment(dates[0]).format("YYYY-MM-DD"),
      dateEnd: moment(dates[1]).format("YYYY-MM-DD"),
      granularity: props.filters?.isGranularity ? props.filters?.granularities.find((g) => g.checked === true)?.value : params.granularity || undefined,
      hours: getParamsFilter(props.filters?.hours),
      accounts: props.type === 'billing' ? getParamsFilter(props.filters?.accounts) : undefined,
      advertisers: props.type === 'advertising' ? getParamsFilter(props.filters?.advertisers) : undefined,
      publishers: getParamsFilter(props.filters?.publishers),
      countries: getParamsFilter(props.filters?.countries),
      sources: getParamsFilter(props.filters?.sources),
      platforms: getParamsFilter(props.filters?.platforms),
      sourceVersions: getParamsFilter(props.filters?.sourceVersions),
      sdkVersions: getParamsFilter(props.filters?.sdkVersions),
      placementTypes: getParamsFilter(props.filters?.placementTypes),
      placementPhases: props.type === 'advertising' ? getParamsFilter(props.filters?.placementPhases) : undefined,
      campaignTypes: props.type === 'advertising' ? getParamsFilter(props.filters?.campaignTypes) : undefined,
      roasPhases: props.type === 'advertising' ? getParamsFilter(props.filters?.roasPhases) : undefined,
      bidPriceSources: props.type === 'advertising' ? getParamsFilter(props.filters?.bidPriceSources) : undefined,
      applications: props.type === 'advertising' ? getParamsFilter(props.filters?.applications) : undefined,
      campaigns: props.type === 'advertising' ? getParamsFilter(props.filters?.campaigns) : undefined,
      adTypes: getParamsFilter(props.filters?.adTypes),
      creativeTypes: props.type === 'advertising' ? getParamsFilter(props.filters?.creativeTypes) : undefined,
      adPhases: props.type === 'advertising' ? getParamsFilter(props.filters?.adPhases) : undefined,
      bidAbTests: props.type === 'advertising' ? getParamsFilter(props.filters?.bidAbTests) : undefined,
      abTests: getParamsFilter(props.filters?.abTests),
      abTestVersions: getParamsFilter(props.filters?.abTestVersions),
      deviceApps: props.type === 'advertising' ? getParamsFilter(props.filters?.deviceApps) : undefined,
      deviceAttempts: props.type === 'advertising' ? getParamsFilter(props.filters?.deviceAttempts) : undefined,
      rangeBidIrs: props.type === 'advertising' ? getParamsFilter(props.filters?.rangeBidIrs) : undefined,
      hasIfa: props.type === 'advertising' ? getParamsFilter(props.filters?.hasIfa) : undefined,
      is: getParamsIs(),
    };
    if (stringify) {
      return qs.stringify(b, { encode: false });
    }
    return b;
  }

  const allFilters = useMemo(() => props.filters ? [
    {
      acceptedTypes: ['advertising', 'billing'],
      component: <Form.Item key='date'><YcDatePicker value={dates} onChange={(e) => { if (e && e.length >= 2) { _setDates([e[0]!, e[1]!]); } }} /></Form.Item>,
    },
    {
      acceptedTypes: ['advertising', 'billing'],
      component: <Filter key='granularity' filters={props.filters} is='isGranularity' label="Granularities" items='granularities' onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='hours' filters={props.filters} is='isHourOfDay' label="Hours" items='hours' multiple onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['billing'],
      component: <Filter key='accounts' filters={props.filters} is='isAccount' label="Accounts" items='accounts' multiple onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='advertisers' filters={props.filters} is='isAdvertiser' label="Advertisers" items='advertisers' multiple onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='publishers' filters={props.filters} is='isPublisher' label="Publishers" items='publishers' multiple onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='countries' filters={props.filters} is='isCountry' label="Countries" items='countries' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='sources' filters={props.filters} is='isSource' label="Sources" items='sources' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='sourceVersions' filters={props.filters} is='isSourceVersion' label="Source Version" items='sourceVersions' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='platforms' filters={props.filters} is='isPlatform' label="Platforms" items='platforms' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='sdkVersions' filters={props.filters} is='isSdkVersion' label="SDK Versions" items='sdkVersions' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='applications' filters={props.filters} is='isApplication' label="Applications" items='applications' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='campaigns' filters={props.filters} is='isCampaign' label="Campaigns" items='campaigns' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='campaignTypes' filters={props.filters} is='isCampaignType' label="Campaign Types" items='campaignTypes' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='placementTypes' filters={props.filters} is='isPlacementType' label="Placement Types" items='placementTypes' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='placementPhases' filters={props.filters} is='isPlacementPhase' label="Placement Phases" items='placementPhases' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='roasPhases' filters={props.filters} is='isRoasPhase' label="Roas Phases" items='roasPhases' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='bidPriceSources' filters={props.filters} is='isBidPriceSource' label="Bid Price Sources" items='bidPriceSources' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='adTypes' filters={props.filters} is='isAdType' label="Ad Types" items='adTypes' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='creativeTypes' filters={props.filters} is='isCreativeType' label="Creative Types" items='creativeTypes' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='adPhases' filters={props.filters} is='isAdPhase' label="Ad Phases" items='adPhases' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='bidAbTests' filters={props.filters} is='isBidAbTest' label="AB Tests" items='bidAbTests' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='abTests' filters={props.filters} is='isAbTest' label="AB Tests Names" items='abTests' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='abTestVersions' filters={props.filters} is='isAbTestVersion' label="AB Tests Versions" items='abTestVersions' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='deviceNbApp' filters={props.filters} is='isDeviceNbApp' label="Nb Apps" onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='deviceNbAttempt' filters={props.filters} is='isDeviceNbAttempt' label="Nb Attempts" onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='rangeBidIrs' filters={props.filters} is='isRangeBidIr' label="Bid IRs" items='rangeBidIrs' onSetFilters={props.onSetFilters} multiple />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='ads' filters={props.filters} is='isAd' label="Ads" onSetFilters={props.onSetFilters} />,
    },
    {
      acceptedTypes: ['advertising'],
      component: <Filter key='hasIfa' filters={props.filters} is='isHasIfa' label="Has IDFA" items='hasIfa' onSetFilters={props.onSetFilters} multiple />,
    },
  ] : [], [dates, props.filters, props.onSetFilters]);

  return (
    <div id='reporting-filter'>
      <Spin spinning={loading}>
        {!!props.filters &&
          <Form>
            {allFilters.map((filter) => (filter.acceptedTypes.includes(props.type) && filter.component))}
            <Form.Item>
              <Button type="primary" onClick={() => {
                uHistory.push({
                  search: getParams(true) as string,
                });
                props.onSearch(getParams(false));
              }}>Search</Button>
            </Form.Item>
          </Form>
        }
      </Spin>
    </div>
  );
}
