import moment from "moment";
import { useCallback, useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Button, Tooltip, Spin, InputNumber } from 'antd';
import { ColumnType } from "antd/lib/table";
import {
  AndroidFilled,
  AppleFilled,
  PlayCircleOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  PauseCircleOutlined,
  SaveOutlined,
  CheckOutlined,
  CloseOutlined,
  FileSearchOutlined,
  ExceptionOutlined,
  AuditOutlined,
  FileUnknownOutlined
} from '@ant-design/icons';
import WarningFilled from "@ant-design/icons/lib/icons/WarningFilled";

import { SearchBar, YcDatePicker, YcIconName, YcTitle } from "@/components";
import { MCampaign, uniques } from '@/modeles';
import { campaignsService } from '@/services';
import { FilterTool, NumberTools } from "@/tools";
import { useAuths, useCap } from "@/contexts";
import { usePageTitle } from "@/hooks/use-title";

import './campaigns.page.scss';

import { useCampaigns } from "./useCampaigns";
import { YcReviewPopup } from "../../components";

const NameCell = (text: string, record: MCampaign) => (
  <Tooltip title="Edit">
    <Link to={"/advertising/campaigns/campaign/" + record.key} className='link-withicon'>
      {record.name}
      <EditOutlined className='lw-icon' />
    </Link>
  </Tooltip>
);

const KeyCell = (text: string, record: MCampaign) => {
  const { user } = useAuths();
  return (
    <Tooltip title="Edit">
      <Link to={"/advertising/campaigns/campaign/" + record.key} className='link-withicon'>
        {record.key}
        {
          !record.Application.isCallbackCreated && user?.accountId === 1 && (
            <Tooltip title="Missing MMP callback">
              <WarningFilled className="mmp-warning ms-3" color="red" />
            </Tooltip>
          )
        }
        <EditOutlined className='lw-icon' />
      </Link>
    </Tooltip>
  );
};

const AppCell = (text: string, record: MCampaign) => (
  <Tooltip title="Go to application">
    <YcIconName app={record.Application} showIcon={true} />
  </Tooltip>
)

const PlatformCell = (text: string, campaign: MCampaign) => (
  <>
    {campaign.Application.platform === "android" && <AndroidFilled />}
    {campaign.Application.platform === "ios" && <AppleFilled />}
    &nbsp;{campaign.Application.platform}
  </>
);

const Summary = ({ campaigns, total, hasReporting }: { campaigns: MCampaign[], total, hasReporting: boolean }) => {
  const { totalBudget, totalTodaySpend, totalIsActive } = campaigns.reduce((acc, c) => {
    acc.totalBudget += c.budget! || 0;
    acc.totalTodaySpend += c.spendDay! || 0;
    acc.totalIsActive += Number(c.isActive);

    return acc;
  }, { totalBudget: 0, totalTodaySpend: 0, totalIsActive: 0 });

  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={5}><b>Total</b></Table.Summary.Cell>
        <Table.Summary.Cell index={5}>${totalBudget || undefined}</Table.Summary.Cell>
        <Table.Summary.Cell index={6}>{NumberTools.roundPrice(totalTodaySpend, 2)}</Table.Summary.Cell>
        <Table.Summary.Cell index={7}></Table.Summary.Cell>
        <Table.Summary.Cell index={8}><b>Active {totalIsActive} / {campaigns.length}</b></Table.Summary.Cell>
        {hasReporting &&
          <>
            <Table.Summary.Cell index={9}>{total.impressions || undefined}</Table.Summary.Cell>
            <Table.Summary.Cell index={10}>{total.clicks || undefined}</Table.Summary.Cell>
            <Table.Summary.Cell index={11}>{total.installs || undefined}</Table.Summary.Cell>
            <Table.Summary.Cell index={12}>{NumberTools.roundPercentage(total.ir, 2)}</Table.Summary.Cell>
            <Table.Summary.Cell index={13}>{NumberTools.roundPrice(total.ecpi, 2)}</Table.Summary.Cell>
            <Table.Summary.Cell index={14}>{NumberTools.roundPrice(total.ecpm, 2)}</Table.Summary.Cell>
            <Table.Summary.Cell index={15}>{NumberTools.roundPrice(total.spend, 2)}</Table.Summary.Cell>
          </>
        }
      </Table.Summary.Row>
    </>
  );
};

function Budget(props: { text: string, record: MCampaign, campaigns, _setCampaigns }) {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [budget, setBudget] = useState(props.record.budget);
  const { refreshCap } = useCap();

  const handleEditBudget = async () => {
    setLoading(true);
    if (budget && budget !== props.record.budget) {
      await campaignsService.editBudget(props.record?.key, budget);
      refreshCap();
      props.record.budget = budget;
      props._setCampaigns([...props.campaigns]);
    }
    setEditing(false);
    setLoading(false);
  }

  return (
    <Spin spinning={loading}>
      {editing ? (
        <>
          <InputNumber prefix='$' value={budget} onChange={(newBudget) => {
            if (newBudget && newBudget !== props.record.budget) {
              setBudget(newBudget);
            }
          }} />
          <Tooltip title="Save">
            <Button type="link" shape="circle" icon={<SaveOutlined />} onClick={() => handleEditBudget()} />
          </Tooltip>
        </>
      ) : <span>${props.text}</span>}
      <Tooltip title="Edit">
        <Button type="link" shape="circle" onClick={() => setEditing(!editing)}>
          <EditOutlined />
        </Button>
      </Tooltip>
    </Spin>
  );
}

export function CampaignsPage(props: {
  isSuperAdmin?: boolean
}) {
  usePageTitle('Campaigns');
  const [selectedCampaignReview, setSelectedCampaignReview] = useState<MCampaign | null>(null);

  const {
    loading,
    dates,
    _setDates,
    campaigns,
    campaignsToValidate,
    _setCampaigns,
    onLoadStats,
    onSearch,
    displayCampaigns,
    hasReporting,
    total,
  } = useCampaigns(props.isSuperAdmin);

  const IsActiveCell = useCallback((text: string, campaign: MCampaign) =>
    <div className="block-bt link-withicon" onClick={async () => {
      if (campaign) {
        if (await campaignsService.activate(campaign.key, !campaign.isActive)) {
          campaign.isActive = !campaign.isActive;
          _setCampaigns([...campaigns]);
        }
      }
    }}>
      {campaign.isActive && ((campaign.budget || 0) > (campaign.spendDay || 0)) && <div><PlayCircleOutlined style={{ color: 'green' }} /> <b>Active</b><PauseCircleOutlined style={{ color: 'red' }} className="lw-icon" /></div>}
      {!campaign.isActive && <div><PauseCircleOutlined style={{ color: 'red' }} /> <b>Inactive</b><PlayCircleOutlined style={{ color: 'green' }} className="lw-icon" /></div>}
      {campaign.isActive && ((campaign.budget || 0) <= (campaign.spendDay || 0)) && <div><IssuesCloseOutlined style={{ color: 'orange' }} /> <b>Cap</b><PauseCircleOutlined style={{ color: 'red' }} className="lw-icon" /></div>}

    </div>, [_setCampaigns, campaigns]);

  const IsValideCell_SuperAdmin = useCallback((text: string, campaign: MCampaign) =>
    <div>
      {
        ((campaign.isValide === null && campaign.previewStatus?.status === 'valid')) &&
        <Button icon={<AuditOutlined style={{ color: 'green', fontSize: '18px' }} />} onClick={() => { setSelectedCampaignReview(campaign) }}>Review</Button>
      }
      {
        ((campaign.isValide === null && campaign.previewStatus?.status === 'warning')) &&
        <Tooltip title={campaign.previewStatus?.message.map((m) => {
          return `- ${m}\n`;
        })}>
          <Button icon={<FileSearchOutlined style={{ color: 'orange', fontSize: '18px' }} />} onClick={() => { setSelectedCampaignReview(campaign) }}>Review</Button>
        </Tooltip>
      }
      {
        ((campaign.isValide === null && campaign.previewStatus?.status === 'error')) &&
        <Tooltip title={campaign.previewStatus?.message.map((m) => {
          return `- ${m}\n`;
        })}>
          <Button icon={<ExceptionOutlined style={{ color: 'red', fontSize: '18px' }} />} onClick={() => { setSelectedCampaignReview(campaign) }}>Review</Button>
        </Tooltip>
      }
      {
        ((campaign.isValide === null && (campaign.previewStatus === null || campaign.previewStatus === undefined))) &&
        <Tooltip title="NULL">
          <Button icon={<FileUnknownOutlined style={{ color: 'blue', fontSize: '18px' }} />} onClick={() => { setSelectedCampaignReview(campaign) }}>Review</Button>
        </Tooltip>
      }
      {(campaign.isValide === true) &&
        <Button shape="circle" icon={<CloseOutlined style={{ color: 'red' }} />} onClick={
          async () => {
            if (campaign) {
              if (await campaignsService.invalidate(campaign.key)) {
                campaign.isValide = false;
                _setCampaigns([...campaigns]);
              }
            }
          }
        } />
      }
      {(campaign.isValide === false) &&
        <Button shape="circle" icon={<CheckOutlined style={{ color: 'green' }} />} onClick={
          async () => {
            if (campaign) {
              if (await campaignsService.validate(campaign.key)) {
                campaign.isValide = true;
                _setCampaigns([...campaigns]);
              }
            }
          }
        } />
      }
    </div >, [campaigns, _setCampaigns]);

  const baseColumns = useCallback<(isReview?: boolean) => ColumnType<MCampaign>[]>((isReview = false) => [
    {
      title: 'Name', key: 'name',
      render: NameCell,
      sorter: (a: MCampaign, b: MCampaign) => a.name.localeCompare(b.name),
    },
    {
      title: 'Key', key: 'key',
      render: KeyCell,
      sorter: (a: MCampaign, b: MCampaign) => a.key.localeCompare(b.key),
    },
    {
      title: 'Application', key: 'applicationId',
      render: AppCell,
      sorter: (a: MCampaign, b: MCampaign) => a.Application.name.localeCompare(b.Application.name),
    },
    {
      title: 'Platform', key: 'platform',
      render: PlatformCell,
      sorter: (a, b) => a.Application.platform.localeCompare(b.Application.platform || '') || -1,
      filters: [{ text: 'Android', value: 'android' }, { text: 'iOS', value: 'ios' }],
      onFilter: (value, record) => record.Application.platform?.toLowerCase() === (value as string).toLowerCase(),
    },
    {
      title: 'Created', dataIndex: 'createdAt', key: 'createdAt',
      sorter: (a: MCampaign, b: MCampaign) => a.createdAt!.toString().localeCompare(b.createdAt!.toString()),
      render: (text: string, record: MCampaign) => moment(record.createdAt).format("YYYY-MM-DD HH:mm:ss"),
      ...(isReview ? { defaultSortOrder: 'descend' as const } : {}),
    },
    {
      title: 'Daily budget', dataIndex: 'budget', key: 'budget',
      sorter: (a: MCampaign, b: MCampaign) => a.budget! - b.budget!,
      render: (a, b) => <Budget text={a} record={b} campaigns={campaigns} _setCampaigns={_setCampaigns} />,
    },
    {
      title: 'Spend Today', dataIndex: 'spendDay', key: 'spendDay',
      sorter: (a: MCampaign, b: MCampaign) => a.spendDay! - b.spendDay!,
      render: (text: string, record: MCampaign) => NumberTools.roundPrice(record.spendDay!, 2),
      ...(isReview ? {} : { defaultSortOrder: 'descend' as const }),
    },
    {
      title: 'Valid', dataIndex: 'isValide', key: 'isValide',
      sorter: (a: MCampaign, b: MCampaign) => Number(a.isValide) - Number(b.isActive),
      render: (text: string, campaign: MCampaign) =>
        <>
          {campaign.isValide === true && <><CheckOutlined style={{ color: 'green' }} /> <b>Valid</b></>}
          {campaign.isValide === false && <><CloseOutlined style={{ color: 'red' }} /> <b>Inactive</b></>}
          {campaign.isValide === null && <><CloseOutlined style={{ color: 'orange' }} /> <b>Waiting for review</b></>}
        </>,
      filters: FilterTool.BOOL_filters('Validate', 'Invalidate', 'Waiting'),
      onFilter: FilterTool.BOOL_onFilter('isValide'),
    },
    {
      title: 'Active', dataIndex: 'isActive', key: 'isActive',
      sorter: (a: MCampaign, b: MCampaign) => Number(a.isActive) - Number(b.isActive),
      render: IsActiveCell,
      filters: FilterTool.BOOL_filters('Active', 'Inactive'),
      onFilter: FilterTool.BOOL_onFilter('isActive'),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [IsActiveCell]);

  const reportingColumns = useMemo<ColumnType<MCampaign>>(() => ({
    title: 'Reporting',
    children: [
      {
        title: 'Impr.', dataIndex: 'impressions', key: 'impressions', defaultSortOrder: 'descend' as const,
        sorter: (a: MCampaign, b: MCampaign) => (a.impressions || 0) - (b.impressions || 0),
      },
      { title: 'Clicks', dataIndex: 'clicks', key: 'clicks', sorter: (a: MCampaign, b: MCampaign) => a.clicks - b.clicks },
      { title: 'Inst.', dataIndex: 'installs', key: 'installs', sorter: (a: MCampaign, b: MCampaign) => a.installs - b.installs },
      {
        title: 'IR', key: 'ir',
        sorter: (a: MCampaign, b: MCampaign) => a.ir - b.ir,
        render: (text: string, record: MCampaign) => NumberTools.roundPercentage(record.ir, 2),
      },
      {
        title: 'eCPI', dataIndex: 'ecpi', key: 'ecpi',
        sorter: (a: MCampaign, b: MCampaign) => a.ecpi - b.ecpi,
        render: (text: string, record: MCampaign) => NumberTools.roundPrice(record.ecpi, 2),
      },
      {
        title: 'eCPM', dataIndex: 'ecpm', key: 'ecpm',
        sorter: (a: MCampaign, b: MCampaign) => a.ecpm - b.ecpm,
        render: (text: string, record: MCampaign) => NumberTools.roundPrice(record.ecpm, 2),
      },
      {
        title: 'Spend', dataIndex: 'spend', key: 'spend',
        sorter: (a: MCampaign, b: MCampaign) => a.spend - b.spend,
        render: (text: string, record: MCampaign) => NumberTools.roundPrice(record.spend, 2),
      }
    ],
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const adminColumns = useMemo<ColumnType<MCampaign>>(() => ({
    title: 'Super Admin',
    className: 'columns-superadmin',
    children: [
      {
        title: 'Account', dataIndex: 'Account.name', key: 'Account.name',
        sorter: (a: MCampaign, b: MCampaign) => Number(a.isValide) - Number(b.isActive),
        render: (text: string, record: MCampaign) => record.Account?.name,
        filters: FilterTool.STRING_filters(uniques(campaigns.map(c => c.Account?.name || ''))),
        onFilter: FilterTool.STRING_onFilter((record: MCampaign) => record.Account?.name || ''),
      },
      {
        title: 'Valid', dataIndex: 'isValide', key: 'isValide',
        render: IsValideCell_SuperAdmin,
        align: 'center',
      },
    ],
  }), [IsValideCell_SuperAdmin, campaigns]);

  const columns = useCallback((isReview = false) => {
    if (props.isSuperAdmin) {
      if (hasReporting) {
        return [
          { title: 'Campaign', children: baseColumns(isReview) },
          reportingColumns,
          adminColumns,
        ];
      }
      return [
        { title: 'Campaign', children: baseColumns(isReview) },
        adminColumns,
      ];
    }
    if (hasReporting) {
      return [
        { title: 'Campaign', children: baseColumns(false) },
        reportingColumns,
      ];
    }
    return baseColumns(false);
  }, [hasReporting, baseColumns, reportingColumns, adminColumns, props]);

  return (
    <div id='ua-campaigns'>
      <YcTitle label="Campaigns" addUrl="/advertising/campaigns/campaign"></YcTitle>
      <Spin spinning={loading}>
        <div>
          <YcDatePicker
            value={dates}
            onChange={(e) => {
              if (e && e.length >= 2) {
                _setDates([e[0]!, e[1]!]);
              }
            }}
            presets={['Yesterday', 'Last 7d', 'Last 30d', 'Current month', 'Previous month', 'Year to date']}
            disabledDate={d => !d || d.isAfter(moment().utc().subtract(1, 'day'))} />
          <Button type="primary" onClick={() => { onLoadStats(); }}>Load Reporting</Button>
        </div>
        <SearchBar onSearch={onSearch} placeholder={"Filter by app name or bundle"} />
        {(props.isSuperAdmin && campaignsToValidate.length) ?
          <>
            <Table
              bordered size="small"
              dataSource={campaignsToValidate}
              columns={columns(true).filter((c) => (c.title !== 'Reporting'))}
              rowKey="key" />
            <YcReviewPopup
              campaign={selectedCampaignReview}
              cancelReview={() => { setSelectedCampaignReview(null); }}
              validReview={async (status: boolean) => {
                if (selectedCampaignReview) {
                  const fct = status ? 'validate' : 'invalidate';
                  await campaignsService[fct](selectedCampaignReview.key)
                  selectedCampaignReview.isValide = status;
                  _setCampaigns([...campaigns]);
                  setSelectedCampaignReview(null);
                }
              }} />
          </>
          : <></>}
        <Table
          bordered size="small"
          dataSource={displayCampaigns}
          columns={columns(false)}
          summary={() => <Summary campaigns={campaigns} total={total} hasReporting={hasReporting} />}
          rowKey="key"
          pagination={{ position: ['bottomRight'], pageSizeOptions: ['10', '20', '50', '100'], defaultPageSize: 20 }}
        />
      </Spin>
    </div>
  );
}
