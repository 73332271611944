export enum BidErrors {
  OK = 0,

  // Code 1000+ related to bidding filters or errors
  INTERNAL = 1000,
  NO_BID = 1001,
  AD_LIST_EMPTY = 1100,
  AD_LIST_EMPTY_APP_PUBLISHER_FILTER = 1101,
  AD_LIST_EMPTY_APP_BUNDLE_FILTER = 1102,
  AD_LIST_EMPTY_DEVICE_OS_FILTER = 1103,
  AD_LIST_EMPTY_DEVICE_GEO_COUNTRY_FILTER = 1104,
  AD_LIST_EMPTY_AD_FILTER = 1110,
  SELECTED_AD_IS_NULL = 1200,
  CAMPAIGN_AD_NOT_FOUND = 1210,

  // Code 2000+ related to the bid request placement filters or errors
  BID_PLACEMENT_FILTER = 2000,
  BID_PLACEMENT_FILTER_NOT_FOUND = 2100,
  BID_PLACEMENT_FILTER_NOT_ACTIVE = 2101,
  BID_PLACEMENT_FILTER_TYPE_NOT_FOUND = 2110,
  BID_PLACEMENT_FILTER_TYPE_NOT_SUPPORTED = 2111,
  BID_PLACEMENT_FILTER_TYPE_DISABLED = 2112,
  BID_PLACEMENT_FILTER_TYPE_NOT_MATCHING = 2113,
  BID_PLACEMENT_FILTER_OS_NOT_MATCHING = 2114,
  BID_PLACEMENT_FILTER_APP_BUNDLE_NOT_FOUND = 2120,
  BID_PLACEMENT_FILTER_APP_BUNDLE_NOT_MATCHING = 2121,
  BID_PLACEMENT_FILTER_SDK_VERSION_NOT_FOUND = 2130,

  // Code 3000+ related to the request filters or errors
  BID_REQUEST_FILTER = 3000,
  BID_REQUEST_FILTER_IMP_NOT_FOUND = 3100,
  BID_REQUEST_FILTER_IMP_MULTIPLE_NOT_SUPPORTED = 3101,
  BID_REQUEST_FILTER_IMP_TYPE_NOT_SUPPORTED = 3102,
  BID_REQUEST_FILTER_IMP_VIDEO_PROTOCOL_NOT_SUPPORTED = 3103,
  BID_REQUEST_FILTER_APP_NOT_FOUND = 3200,
  BID_REQUEST_FILTER_APP_BUNDLE_NOT_FOUND = 3210,
  BID_REQUEST_FILTER_APP_PUBLISHER_NOT_FOUND = 3230,
  BID_REQUEST_FILTER_APP_PUBLISHER_DISABLED = 3231,
  BID_REQUEST_FILTER_DEVICE_NOT_FOUND = 3300,
  BID_REQUEST_FILTER_DEVICE_IFA_NOT_FOUND = 3310,
  BID_REQUEST_FILTER_DEVICE_OS_NOT_FOUND = 3320,
  BID_REQUEST_FILTER_DEVICE_OS_NOT_SUPPORTED = 3321,
  BID_REQUEST_FILTER_DEVICE_OSV_NOT_FOUND = 3330,
  BID_REQUEST_FILTER_DEVICE_OSV_NOT_SUPPORTED = 3331,
  BID_REQUEST_FILTER_DEVICE_GEO_NOT_FOUND = 3340,
  BID_REQUEST_FILTER_DEVICE_GEO_COUNTRY_NOT_FOUND = 3350,
  BID_REQUEST_FILTER_DEVICE_GEO_COUNTRY_NOT_SUPPORTED = 3351,
  BID_REQUEST_FILTER_DEVICE_GEO_COUNTRY_DISABLED = 3352,
  BID_REQUEST_FILTER_SDK_VERSION_NOT_FOUND = 3400,
  BID_REQUEST_FILTER_SDK_VERSION_NOT_SUPPORTED = 3401,
  BID_REQUEST_FILTER_SDK_PLACEMENT_NOT_FOUND = 3410,
  BID_REQUEST_FILTER_SDK_PLACEMENT_TYPE_NOT_FOUND = 3420,
  BID_REQUEST_FILTER_SDK_PLACEMENT_TYPE_NOT_SUPPORTED = 3421,
  BID_REQUEST_FILTER_SDK_PLACEMENT_TYPE_DISABLED = 3422,
  BID_REQUEST_FILTER_TOO_MANY_ATTEMPTS = 3500,
  BID_REQUEST_FILTER_TOO_MANY_ATTEMPTS_WITH_NO_RESPONSE = 3501,
  BID_REQUEST_FILTER_TOO_MANY_ATTEMPTS_WITH_BID_PRICE_TOO_LOW = 3502,
  BID_REQUEST_FILTER_TOO_MANY_WINS = 3510,
  BID_REQUEST_FILTER_TOO_MANY_IMPRESSIONS = 3520,

  // Code 4000+ Filters related to the bid response filters or errors
  BID_RESPONSE_FILTER = 4000,
  BID_RESPONSE_FILTER_BID_PRICE_0 = 4100,
  BID_RESPONSE_FILTER_BID_PRICE_LOWER_THAN_BID_FLOOR = 4101
}

export default BidErrors;
