export enum BidAbTests {
  CONTROL = 'control',
  TEST_DEVICE = 'test-device',
  NO_BID = 'no-bid',
  NO_LEARNING = 'no-learning',
  LEARNING = 'learning',
  LEARNING_20 = 'learning-20',

  // Testing abs
  BASIC = 'basic',
  ML = 'ml',
  ML_BASIC = 'ml-basic',
  NO_BID_FLOOR = 'no-bid-floor',
  ROI_REG_OFF = 'roi-reg-off',
  ROI_REG_0 = 'roi-reg-0',
  ROI_REG_MAX = 'roi-reg-max',
  ROI_REG_NO_MAX = 'roi-reg-no-max',
  ROI_REG_NEG = 'roi-reg-neg',
  ROI_REG_NEG_0 = 'roi-reg-neg-0',
  ROI_REG_NEG_MAX = 'roi-reg-neg-max',
  ROI_REG_NEG_NO_MAX = 'roi-reg-neg-no-max',
  DISABLE_BONUS = 'disable-bonus',
  DISABLE_BONUS_M = 'disable-bonus-m',
  DISABLE_BONUS_UA = 'disable-bonus-ua',
  LOW_ASSET_QUALITY = 'low-asset-quality',
  HIGH_ASSET_QUALITY = 'high-asset-quality',
  ORIGINAL_ASSET_QUALITY = 'original-asset-quality',
  WEBVIEW_LOAD = 'webview-load',
  SDK_LOAD = 'sdk-load',
  RAND_AD_TOP_3 = 'rand-ad-top-3',
  AD_TOP_5 = 'ad-top-5',
  AD_COMPUTE_ICON_FIRST = 'ad-compute-icon-first',
  AD_COMPUTE_TRUSTED = 'ad-compute-trusted',
  AD_NO_FILTER = 'ad-no-filter',
  AD_ICON_FILTER = 'ad-icon-filter',
  IMP_X = 'imp-x',
  DIRECT_TRACK = 'direct-track',
  S2S_TRACK = 's2s-track',
  REMOVE_BATCH_TRACK = 'remove-batch-track',
  OVERLAY = 'overlay',
  MAX_BID_IR_NO_LIMIT = 'max-bid-ir-no-limit',
  TOP_CREA_PRED = 'top-crea-pred',
  FILTER_DEVICE_AD = 'filter-device-ad',
  WP = 'wp',
  SWP = 'swp',
  MB_0 = 'mb-0',
  MB_5 = 'mb-5',
  MB_10 = 'mb-10',
  MB_FINAL_10 = 'mb-final-10',
  WP_MIN = 'wp-min',
  WP_MAX = 'wp-max',
  ML_NEXT = 'ml-next-22f',
  ML_NEXT_24 = 'ml-next-24',
  ML_OLD = 'ml-old-22e',
  CONTENT_RATING = 'content-rating',
  ROAS_D0_80 = 'roas-d0-80',
  LEARNING_10 = 'l-10',
  MB_OLD = 'mb-old',
  BF_05 = 'bf-05',
  NO_BF = 'no-bf',
  NO_BF_ALL = 'no-bf-all',
  BF_MAX = 'bf-max',
  BASIC_2 = 'basic-2',
  BASIC_ML = 'basic-ml',
  BASIC_OP = 'basic-op',
  AD_TYPE_50 = 'ad-type-50',
  BASIC_AD_TYPE_50 = 'basic-ad-type-50',
  TOP_CREA_PRED_2 = 'top-crea-pred-2',
  ATT_WP = 'att-wp',
  ATT_1_M_OFF = 'att-1-m-off',
  USER_LEVEL_ROAS = 'user-level-roas',
  USER_LEVEL_ROAS_AVG = 'user-level-roas-avg',
  USER_LEVEL_ROAS_SRC = 'user-level-roas-src',
  ROAS_G = 'roas-g',
  ROAS_F = 'roas-f',
  MODEL_IR = 'model-ir',
  THOMPSON_L_100 = 'thompson-l-100',
  NO_BID_HP_WP = 'no-bid-hp-wp',
  ATT_NO_LIMIT = 'att-no-limit',
  ATT_NO_LIMIT_NO_BID_HP = 'att-no-limit-no-bid-hp'
}

export default BidAbTests;
